<script>
import QRCode from 'qrcode';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "",
            items: [],
            loading: false,
            messagelist: [],
            page: 1,
            userinfo: [],
            admincard: [],
            tabsname: "bracelet",
            wechat_list: [],
            qrcodeloading: true,
            show_wechat_bind_qrcode: false,
            qrcodecountdown: "",
            qrcodeexpire_seconds: 60,
            qrcodelink: "",
            wechat_config: {},
        };
    },
    mounted() {
        this.title = this.$t("menuitems.setting.admins.text");
        this.items = [
            {
                text: this.$t("menuitems.setting.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.setting.admins.text"),
                active: true,
            },
        ];
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.$bus.$on('cardData', (data) => {
            const exists = this.admincard.some(item => item.hex === data);

            if (!exists) {
                // 如果不存在相同的 hex 数据，则添加
                this.admincard.push({ hex: data, name: '' });
            } else {
                // 可以执行其他逻辑，比如提醒用户数据已存在
                this.$message({
                    message: this.$t("menuitems.setting.admins.cardhex") + data + this.$t("global.exists"),
                    type: "warning",
                });
            }
        });
        this.getadminsbracelet();
        this.get_admin_wechat_list();
        this.$bus.$on('message', (data) => {
            if (data.action === 'bind' && data.status === 'success') {
                this.bind_wechat_account(data.code, data.openid);
            }
        });
    },
    methods: {
        addConfig() {
            this.admincard.push({ hex: '', name: '' });
        },
        removeConfig(index, type) {
            if (type === 'admincard') this.admincard.splice(index, 1);
            if (type === 'wechat_list') this.wechat_list.splice(index, 1);
        },
        getadminsbracelet() {
            var that = this
            that.loading = true;
            that.$axios
                .post(that.apiuri,
                    {
                        action: "getadminsbracelet"
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    })
                .then((response) => {
                    that.loading = false;
                    if (response.data.status != 200) {
                        that.$message({
                            message: that.$t("global.failed"),
                            type: "error",
                        });
                        return;
                    }
                    that.admincard = response.data.data;
                })
                .catch((error) => {
                    that.loading = false;
                    that.$message({
                        message: that.$t("global.error"),
                        type: "error",
                    });
                });
        },
        saveadmins(route) {
            var that = this
            that.loading = true;

            if (route == 'bracelet') {
                that.$axios
                    .post(that.apiuri, { action: `saveadmins${route}`, data: that.admincard },
                    {
                        headers: { Openid: that.userinfo.openid },
                    })
                    .then((response) => {
                        that.loading = false;
                        if (response.data.status != 200) {
                            that.$message({
                                message: that.$t("global.failed"),
                                type: "error",
                            });
                            return;
                        }
                        that.$message({
                            message: that.$t("global.success"),
                            type: "success",
                        });
                        that.getadminsbracelet()
                    })
                    .catch((error) => {
                        that.loading = false;
                        that.$message({
                            message: that.$t("global.error"),
                            type: "error",
                        });
                    });
            }else if(route == 'wechat'){
                that.$axios
                    .post(that.apiuri, { action: `saveadmins${route}`, data: that.wechat_list },
                    {
                        headers: { Openid: that.userinfo.openid },
                    })
                    .then((response) => {
                        that.loading = false;
                        if (response.data.status != 200) {
                            that.$message({
                                message: that.$t("global.failed"),
                                type: "error",
                            });
                            return;
                        }
                        that.$message({
                            message: that.$t("global.success"),
                            type: "success",
                        });
                        that.get_admin_wechat_list()
                    })
                    .catch((error) => {
                        that.loading = false;
                        that.$message({
                            message: that.$t("global.error"),
                            type: "error",
                        });
                    });
            }

        },
        get_admin_wechat_list() {
            var that = this
            that.loading = true;
            that.$axios
                .post(that.apiuri,
                    {
                        action: "getadminwechatlist"
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    })
                .then((response) => {
                    that.loading = false;
                    if (response.data.status != 200) {
                        that.$message({
                            message: that.$t("global.failed"),
                            type: "error",
                        });
                        return;
                    }
                    that.wechat_list = response.data.data;
                    that.wechat_config = response.data.config;
                })
                .catch((error) => {
                    that.loading = false;
                    that.$message({
                        message: that.$t("global.error"),
                        type: "error",
                    });
                });
        },
        addWecaht() {
            this.show_wechat_bind_qrcode = true;
            this.$axios
                .post(this.apiuri, {
                    action: 'makewechatqrcode'
                },
                    {
                        headers: { Openid: this.userinfo.openid },
                    })
                .then((response) => {
                    if (response.data.status === 200) {
                        const qrcodelink = response.data.data.url;
                        this.generateQRCode(qrcodelink);
                        this.qrcodeexpire_seconds = response.data.data.expire_seconds;
                        this.qrcodecountdown = setInterval(() => {
                            this.qrcodeexpire_seconds--;
                            if (this.qrcodeexpire_seconds === 0) {
                                clearInterval(this.qrcodecountdown); // 停止计时器
                                this.addWecaht();
                            }
                        }, 1000);
                    } else {
                        console.error('获取二维码链接失败');
                    }
                })
                .catch((error) => {
                    console.error('请求失败:', error);
                });
        },
        async generateQRCode(qrcodelink) {
            try {
                const canvas = this.$refs.qrcodeCanvas;
                await QRCode.toCanvas(canvas, qrcodelink, { width: 400, height: 400 });
                this.qrcodeloading = false;
            } catch (error) {
                console.error('二维码生成失败:', error);
            }
        },
        bind_wechat_account(code, openid) {
            this.show_wechat_bind_qrcode = false;
            this.qrcodeloading = true;
            this.wechat_list.push({ openid, name: '' });
        },
        cancel_wechat_bind_qrcode() {
            this.show_wechat_bind_qrcode = false;
            this.qrcodeloading = true;
            clearInterval(this.qrcodecountdown);
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-loading="loading">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <el-tabs v-model="tabsname">
                            <el-tab-pane :label="$t('menuitems.setting.admins.card')" name="bracelet">
                                <p>
                                    <el-button @click="addConfig">{{ $t('menuitems.setting.admins.addcard')
                                        }}</el-button>
                                </p>
                                <el-form ref="admincard" label-width="80px">
                                    <el-form-item v-for="(val, idx) in admincard"
                                        :label="$t('menuitems.setting.admins.cardnumber') + (idx + 1)" :key="idx">
                                        <div class="config-item">
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-input v-model="val.hex"
                                                        :placeholder="$t('menuitems.setting.admins.cardhex')"></el-input>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-input v-model="val.name"
                                                        :placeholder="$t('menuitems.setting.admins.remark')"></el-input>
                                                </el-col>
                                                <el-col :span="4">
                                                    <el-button @click="removeConfig(idx, 'admincard')">{{
                                                        $t('marketing.billing.remove')
                                                        }}</el-button>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="!admincard || admincard.length == 0">
                                        <el-empty :image-size="80" :description="$t('device.error.nodata')"></el-empty>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="saveadmins(tabsname)">{{
                                            $t('marketing.billing.save') }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('menuitems.setting.admins.wechat')" name="wechat">
                                <p><el-button @click="addWecaht">{{ $t('menuitems.setting.admins.addwechat')
                                        }}</el-button></p>
                                <el-form ref="wechat_list" label-width="80px">
                                    <el-form-item v-for="(val, idx) in wechat_list"
                                        :label="$t('menuitems.setting.admins.wechatnumber') + (idx + 1)" :key="idx">
                                        <div class="config-item">
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-input v-model="val.openid" disabled></el-input>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-input v-model="val.name"
                                                        :placeholder="$t('menuitems.setting.admins.remark_wechat')"></el-input>
                                                </el-col>
                                                <el-col :span="4">
                                                    <el-button @click="removeConfig(idx, 'wechat_list')">{{
                                                        $t('marketing.billing.remove')
                                                        }}</el-button>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="!wechat_list || wechat_list.length == 0">
                                        <el-empty :image-size="80" :description="$t('device.error.nodata')"></el-empty>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" v-if="wechat_list.length < wechat_config.limit" @click="saveadmins(tabsname)">{{
                                            $t('marketing.billing.save') }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                        </el-tabs>

                        <el-dialog :title="$t('admin.add.wechat_qrcode')" :visible.sync="show_wechat_bind_qrcode"
                            width="50%">
                            <div class="qrcode-container">
                                <div v-if="qrcodeloading" class="loading">
                                    <div class="spinner"></div>
                                    <p>{{ $t('login.qrcode.loading') }}...</p>
                                </div>
                                <canvas v-show="!qrcodeloading" ref="qrcodeCanvas"></canvas>
                                <p v-show="!qrcodeloading">
                                    {{ $t('login.qrcode.timeout', { time: qrcodeexpire_seconds }) }}
                                </p>
                            </div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="cancel_wechat_bind_qrcode">{{ $t('global.button.cancel')
                                    }}</el-button>
                            </span>
                        </el-dialog>

                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
.qrcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qrcode-container p {
    text-align: center;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
}

.spinner {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.sixNumber {
    display: flex;
    align-items: center;
    width: 100%;
}

.sixNumber .itemInSix {
    margin: 0 2px;
    width: 14%;
    text-align: center;
}

/* 针对 el-input 组件内部的 input 元素应用居中对齐 */
.center-input .el-input__inner {
    text-align: center;
}

/* 可选：调整字体大小和样式以优化视觉效果 */
.center-input .el-input__inner {
    font-size: 18px;
    /* 根据需要调整 */
    font-weight: bold;
    /* 根据需要调整 */
}
</style>